/**세 자릿수마다 콤마 추가 */
export const comma = (text: string | number): string => {
  return text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**방출할 지폐, 동전 수 계산 */
export const calculateCount = (
  price: string | number,
): { bd1Count: number; hp1Count: number; bd2Count: number } => {
  //bd2:10,000원권, bd1: 1,000권, hp1: 500원
  const bd2Count = Math.floor(+price / 10000);
  const priceWithoutBd2 = +price - 10000 * bd2Count;
  const bd1Count = Math.floor(+priceWithoutBd2 / 1000);
  const priceWithoutBd1Bd2 = priceWithoutBd2 - 1000 * bd1Count;
  const hp1Count = Math.ceil(+priceWithoutBd1Bd2 / 500);
  return { bd1Count, hp1Count, bd2Count };
};

/**CMD별 Byte Count 계산 */
export const calculateBC = (cmd: number): number => {
  switch (cmd) {
    case 0x10:
    case 0x11:
    case 0x12:
      return 0x0d;
    case 0x13:
    case 0x16:
    case 0x17:
    case 0x18:
    case 0x19:
      return 0x12;
    default:
      console.error(`Unknown command : ${cmd}`);
      return 0x00;
  }
};

/**전달할 패킷의 Frame Check Code 계산 */
export type PacketExcludingFcc = number[];
export const calculateFCC = (data: PacketExcludingFcc): number => {
  return data.reduce((acc, val) => acc ^ val, 0);
};

/**통신 표현값에서 방출기 표시 에러코드로 변환 */
export const convertErrorCode = (number: number) => {
  const errNumber = number?.toString(16);
  switch (errNumber) {
    case 'a0': //Er14
      return {
        code: 'ER01',
        message:
          '지폐가 접혀있습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '20': //Er2
      return {
        code: 'ER02',
        message:
          '지폐가 방출 통로에 걸렸습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요. ',
      };
    case '30': //Er3
    case '40': //Er4
      return {
        code: 'ER03',
        message:
          '지폐 겹쳐지거나 방출되지 않았습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '50': //Er5
    case '60': //Er6
      return {
        code: 'ER04',
        message:
          '지폐 길이 비정상입니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '70': //Er7
      return {
        code: 'ER05',
        message:
          '지폐 적재 불량입니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '10': //Er1
      return {
        code: 'ER06',
        message:
          '지폐가 부족합니다. 고객센터로 연락해주세요. KTP 고객센터:02-6275-8011',
      };
    case '80': //Er8
    case '90': //Er12
      return {
        code: 'ER07',
        message:
          '기기 고장입니다. 고객센터로 연락해주세요. KTP 고객센터:02-6275-8011',
      };
    default:
      return {
        code: '',
        message: '',
      };
  }
};
