import axios from 'axios';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { modalInfoAtom } from 'store/globalStateAtom';

function useCheckCurrentVersion() {
  const location = useLocation();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const getCurrentVersion = () => {
    return localStorage.getItem('webVersion');
  };

  useEffect(() => {
    axios
      .get(
        'https://s3.ap-northeast-2.amazonaws.com/ktpkiosk.ktaxpay.com/manifest.json',
        { headers: { 'Cache-Control': 'no-store' } },
      )
      .then((res) => {
        const newVersion = res.data.web_version;
        const currentVersion = getCurrentVersion();
        localStorage.setItem('webVersion', newVersion);
        if (!currentVersion) {
          return;
        }
        if (currentVersion !== newVersion) {
          setModalInfo({
            title: '키오스크를 업데이트 해주세요.',
            description: `버튼을 누르면 업데이트됩니다.\n업데이트 완료시 안내에 따라 키오스크를 재연결해주세요.`,
            btnCallback: () => {
              window.location.reload();
            },
            btnText: '업데이트',
            icon: 'ALERT',
          });
        }
      });
  }, [location]);
  return { getCurrentVersion };
}

export default useCheckCurrentVersion;
