import { MAX_BILL_COUNT, MAX_COIN_COUNT } from 'constants/bill.const';
import React from 'react';

type Props = {
  type: string;
  isError: boolean;
  count: number;
};
function CashAmount({ type, isError, count }: Props) {
  return (
    <div className='flex flex-col items-center justify-between flex-1 gap-6 p-8 bg-white rounded-20'>
      <h4 className={isError ? 'text-warning-100' : ''}>
        {type === 'hp1' ? '오백원' : type === 'bd1' ? '천원권' : '만원권'}
      </h4>
      <p>
        {count} / {type === 'hp1' ? MAX_COIN_COUNT : MAX_BILL_COUNT}
      </p>
    </div>
  );
}

export default CashAmount;
